<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="header">
                    <div class="btn-group" role="group">
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'm' }"
                            v-on:click="period_filtering('m')" class="btn waves-effect waves-light">CE
                            MOIS</button>
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'p' }"
                            v-on:click="period_filtering('p')" class="btn waves-effect waves-light">PERSONNALISÉ</button>
                    </div>
                    <ul v-show="period_filter == 'p'" class="header-dropdown m-r--5">
                        <input type="date" v-model="period.start" />
                        -
                        <input type="date" v-model="period.end" />
                        -
                        <button type="button" class="btn btn-blue-grey btn-circle waves-effect waves-circle waves-float"
                            v-on:click="getData()">
                            <i class="material-icons">autorenew</i>
                        </button>
                    </ul>
                </div>
                <!--  -->
                <div v-if="this.presences.length == 0" class="alert bg-black">
                    <center>Pas de données trouvées</center>
                </div>
                <div v-else class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table class="table table-bordered table-striped table-hover tablePresence">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Employé</th>
                                    <th>Heure d'arrivé</th>
                                    <th>Heure de départ</th>
                                    <th>Temps de travail</th>
                                    <th>Zone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="presence in presences" :key="presence.id">
                                    <td>{{ presence.created_at.slice(0, 10) }}</td>
                                    <td>{{ presence.user.first_name }} {{ presence.user.last_name }}</td>
                                    <td>{{ presence.hour_start }}</td>
                                    <td>{{ presence.hour_end }}</td>
                                    <td>{{ (presence.work_time / 3600).toFixed(2) }}</td>
                                    <td>{{ presence.zone.label }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { initdatable } from '@/js/functions.js'

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
//import moment from 'moment';
//import moment from 'moment';

DataTable.use(DataTablesCore);

export default {
    name: 'PresenceTableComponent',
    components: {
        // DataTable
    },
    data() {
        return {
            table_load: false,
            period_filter: 'm',
            period: {},
            presences: [],
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    methods: {

        getData() {
            this.table_load = true
            axios.post(this.$store.state.URL_API + 'getAllClocks', this.period)
                .then((res) => {
                    this.presences = res.data.data
                    initdatable('.tablePresence')
                    this.table_load = false

                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        period_filtering(fil) {
            if (fil != this.filter) {
                this.period_filter = fil
                if (fil == 'm') {
                    this.period = {}
                    this.getData()
                }
            }
        },
    }
}

</script>