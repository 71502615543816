<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <!-- TABLE -->
                <div v-if="this.profiles.length == 0" class="alert bg-black">
                    <center>Pas de données trouvées</center>
                </div>
                <div class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table id="mainTable" class="table table-bordered table-striped table-hover tableDepartement">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Libellé</th>
                                    <th>Heure de début</th>
                                    <th>Heure de fin</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="profile in profiles" :key="profile.id">
                                    <td>{{ profile.id }}</td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="profile.label" type="text" id="lastname" class="form-control"
                                                placeholder="Entrer le libellé du service" required>
                                        </div>
                                    </td>
                                    <td>
                                        <input v-model="profile.hour_start" type="text" id="lastname" class="form-control"
                                                placeholder="Entrer l'heure de début du service" required>
                                    </td>
                                    <td>
                                        <input v-model="profile.hour_end" type="text" id="lastname" class="form-control"
                                                placeholder="Entrer l'heure de fin du service" required>
                                    </td>
                                    <td>
                                        <button
                                            v-on:click="updateProfile(profile.id, profile.label, profile.hour_start, profile.hour_end)"
                                            type="button" class="btn bg-blue waves-effect">
                                            <i class="material-icons">send</i>
                                        </button>
                                        -
                                        <button v-on:click="removeProfile(profile.id)" type="button"
                                            class="btn bg-red waves-effect">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'ShiftsTableComponent',
    components: {

    },
    data() {
        return {
            table_load: false,
            profiles: [],
            users: []
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        await this.getData()
    },
    created() {

    },
    methods: {

        async getData() {
            this.table_load = true
            axios.get(this.$store.state.URL_API + 'shifts')
                .then((res) => {
                    this.profiles = res.data.data
                    this.table_load = false
                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        async updateProfile(id, label, hour_start, hour_end) {
            Swal.fire({
                title: "Confirmer la modification",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.put(this.$store.state.URL_API + 'shifts/' + id, { label: label, hour_start: hour_start, hour_end: hour_end })
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Département modifié',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'error'
                            )
                            console.error(error)
                        })
                }
            })

        },

        async removeProfile(id) {
            Swal.fire({
                title: "Confirmer la suppression",
                text: "Cette action est ireversible",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.delete(this.$store.state.URL_API + 'shifts/' + id)
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Supprimé !',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },
    }
}

</script>