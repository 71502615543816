<template>
    <div class="login-page bg-indigo align.center">

        <div class="login-box bg-indigo">
            <div class="logo bg-indigo">
                <a href="javascript:void(0);" class="demo-choose-skin purple">Attendance <b>RH</b></a>
                <small>Système de gestion des présences LCE</small>
            </div>
            <div class="card">
                <div class="body">
                    <form id="sign_in" @submit.prevent="signin()">
                        <div class="msg">Commencez votre session</div>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">person</i>
                            </span>
                            <div class="form-line">
                                <input type="text" class="form-control" name="username" placeholder="Nom d'utilisateur"
                                    v-model="credentials.email" required autofocus>
                            </div>
                        </div>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">lock</i>
                            </span>
                            <div class="form-line">
                                <input type="password" class="form-control" name="password" placeholder="Mot de passe"
                                    v-model="credentials.password" required>
                            </div>
                        </div>

                        <div class="row m-t-15 m-b--20">
                            <div class="col-xs-12">
                                <button class="btn btn-block bg-indigo waves-effect align-center"
                                    style="display: flex;align-items: center;justify-content: space-between;">
                                    {{ loading ? 'CONNEXION...' : 'SE CONNECTER' }}
                                    <div v-if="loading == false">
                                        <i class="material-icons">trending_flat</i>
                                    </div>
                                    <div v-else class="preloader pl-size-xs">
                                        <div class="spinner-layer pl-white">
                                            <div class="circle-clipper left">
                                                <div class="circle"></div>
                                            </div>
                                            <div class="circle-clipper right">
                                                <div class="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 align-center">
                                <a href="sign-up.html" class="align-center">Mot de passe oublié ?</a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
</template>

<script>
import axios from 'axios'

export default {
    name: 'SigninView',
    data() {
        return {
            loading: false,
            credentials: { email: '', password: '' }
        }
    },
    computed: {
        comp1() {
            return "hello vue js"
        }
    },
    mounted() {
        console.log("mounted")
        // localStorage.clear()
    },
    created() {
        console.log("created")
    },
    methods: {
        signin() {
            this.loading = true
            axios.post(this.$store.state.URL_API + "auth/loginRH", this.credentials, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => {
                    this.loading = false
                    if (res.data.status) {
                        // console.info(res.data)
                        localStorage.setItem('token', res.data.access_token)
                        localStorage.setItem('auth_attendance', JSON.stringify(res.data.user))
                        localStorage.setItem('connected', true)

                        this.$store.state.token = res.data.access_token
                        this.$store.state.auth_attendance = res.data.user
                        this.$store.state.connected = true

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

                        this.$router.push({ path: '/dashboard' })

                    } else {
                        console.info(res.data.message)
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                    this.loading = false
                })
                .catch((e) => {
                    console.error(e)
                    this.loading = false
                    if(e.response.data){
                        this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.data.message,
                            "Désolé",
                        )
                    }
                })
        }
    }
}

</script>