<template>
    <HeadComponent />
    <MainsideComponent target="3" />
    <section class="content">
        <div class="container-fluid">

            <!-- CARDS -->
            <div class="row clearfix">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blak hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">assignment_late</i>
                        </div>
                        <div class="content">
                            <h2 class="text">EXEPTIONS</h2>
                            <div class="number">{{ data.exceptions }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">call_missed</i>
                        </div>
                        <div class="content">
                            <h2 class="text">ABSENCES</h2>
                            <div class="number">{{ data.absences }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">alarm_off</i>
                        </div>
                        <div class="content">
                            <h2 class="text">RETARDS</h2>
                            <div class="number">{{ data.retards }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">directions_run</i>
                        </div>
                        <div class="content">
                            <h2 class="text">DÉPART ANTICIPÉS</h2>
                            <div class="number">{{ data.departs }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-header">
                <h2>REGISTRE</h2>
            </div>
            <!-- REGISTRE -->
            <ExceptionTableComponent/>

            <div class="block-header">
                <h2>CLASSEMENT</h2>
            </div>
            <!-- CLASSEMENT -->
            <ExceptionClassementTableComponent/>

        </div>

        <!--  -->

        <!--  -->
        <div class="page-loader-wrapper" v-if="loader">
            <div class="loader">
                <div class="preloader">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                <p>Chargement...</p>
            </div>
        </div>

        <!--  -->
    </section>
</template>

<script>
import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'
import ExceptionTableComponent from '@/components/tables/ExceptionTable'
import ExceptionClassementTableComponent from '@/components/tables/ExceptionClassementTable'

import axios from 'axios'

export default {
    name: 'ExceptionView',
    components: {
        HeadComponent,
        MainsideComponent,
        ExceptionTableComponent,
        ExceptionClassementTableComponent
    },
    data() {
        return {
            loader: false,
            data: {},
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    methods: {

        getData(){
            this.loader = true
            axios.get(this.$store.state.URL_API + 'getExptionsDetails')
                .then((res) => {
                    this.data.exceptions = res.data.exceptions
                    this.data.absences = res.data.absences
                    this.data.retards = res.data.retards
                    this.data.departs = res.data.departs
                    this.loader = false

                })
                .catch((error) => {
                    console.error(error)
                    this.loader = false
                })
        },

    }
}

</script>