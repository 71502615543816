<template>
    <HeadComponent />
    <MainsideComponent target="8" />
    <section class="content">
        <div class="block-header">
            <div class="btn-group" role="group">
                <button type="button" class="btn waves-effect waves-light" :class="{ 'btn-success': action == 'add' }"
                    v-on:click="switchAction('add')">AJOUTER</button>
                <button type="button" class="btn waves-effect waves-light" :class="{ 'btn-primary': action == 'up' }"
                    v-on:click="switchAction('up')">MODIFIER</button>
                <button type="button" class="btn waves-effect waves-light" :class="{ 'btn-danger': action == 'del' }"
                    v-on:click="switchAction('del')">SUPPRIMER</button>
            </div>
        </div>
        <div class="container-fluid" id="printMe">
            <FullCalendar :options="calendarOptions" />
        </div>

        <!--  -->
        <div class="page-loader-wrapper" v-if="loader">
            <div class="loader">
                <div class="preloader">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                <p>Chargement...</p>
            </div>
        </div>
    </section>
</template>

<script>
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/vue3'

import MainsideComponent from '@/components/menu/MainsideComponent'
import HeadComponent from '@/components/menu/HeadComponent'

import Swal from 'sweetalert2'
import axios from 'axios'

export default {
    name: 'DaysView',
    components: {
        HeadComponent,
        MainsideComponent,
        FullCalendar
    },
    data() {
        return {
            calendarOptions: {
                timeZone: 'UTC',
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                eventClick: this.handleEventClick,
                dateClick: this.handleDateClick,
                initialView: 'dayGridMonth',
                eventColor: '#378006',
                events: this.events,
                selectable: true,
                locale: 'fr',
                firstDay: 1,
            },
            daydata: { label: null, date: null },
            loader: false,
            action: 'add',
            dayId: null,
            events: [],
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        await this.loadpage()
    },
    created() {

    },
    methods: {

        async getAllDays() {
            await axios.get(this.$store.state.URL_API + 'getAllDays')
                .then((res) => {
                    this.days = res.data.data
                    this.events = []
                    this.days.forEach((day) => {
                        this.events.push({
                            title: day.label,
                            date: day.date,
                            extendedProps: {
                                id: day.id
                            },
                        })
                    })
                    this.calendarOptions.events = this.events
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        async loadpage() {
            this.loader = true
            await this.getAllDays()
            this.loader = false
        },

        handleDateClick(arg) {

            if (this.action == 'add') {
                this.addeDay(arg.dateStr)
            }
        },

        handleEventClick(arg) {
            switch (this.action) {
                case 'up':
                    this.updateDay(arg.event.extendedProps.id, arg.event.title)
                    break;
                case 'del':
                    this.removeDay(arg.event.extendedProps.id)
                    break;
                default:
                    break;
            }
        },

        switchAction(act) {
            this.action = act
        },

        async addeDay(date) {
            const { value: label } = await Swal.fire({
                title: 'Ajouter un jour autorisé',
                input: 'text',
                inputLabel: '',
                inputPlaceholder: "Libéllé du jour",
                inputAttributes: {
                    minlength: 5,
                }
            })

            if (label) {
                axios.post(this.$store.state.URL_API + 'addDate', { label: label, date: date })
                    .then(async (res) => {
                        if (res.data.status) {
                            await this.loadpage()
                            Swal.fire(
                                'Succès !',
                                'Ajout terminé',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Oops..',
                                res.data.message,
                                'error'
                            )
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        Swal.fire(
                            'Oops..',
                            error.response.data.message,
                            'erroe'
                        )
                    })
            }
        },

        async updateDay(id, lab) {
            const { value: label } = await Swal.fire({
                title: 'Modifier le label du jour',
                input: 'text',
                inputLabel: '',
                inputPlaceholder: lab,
                inputAttributes: {
                    minlength: 5,
                }
            })

            if (label) {
                console.log(this.daydata)
                axios.post(this.$store.state.URL_API + 'updateDate/' + id, { label: label })
                    .then(async (res) => {
                        if (res.data.status) {
                            await this.loadpage()
                            Swal.fire(
                                'Succès !',
                                'Modification terminée',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Oops..',
                                res.data.message,
                                'error'
                            )
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        Swal.fire(
                            'Oops..',
                            error.response.data.message,
                            'erroe'
                        )
                    })
            }
        },

        async removeDay(id) {
            Swal.fire({
                title: "Confirmer la suppression",
                text: "Cette action est ireversible",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.$store.state.URL_API + 'removeDate/' + id)
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.loadpage()
                                Swal.fire(
                                    'Succès !',
                                    "Ce jour n'est plus autorisé",
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },


    }
}


</script>