<template>
    <HeadComponent />
    <MainsideComponent target="2" />

    <!--  -->
    <section class="content">
        <div class="container-fluid">

            <!--  -->

            <div class="row clearfix">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3">
                        <div class="content">
                            <h2 class="text">HEURE MOYENNE D'ARRIVÉ</h2>
                            <div class="number">{{ data.moy_come }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey">
                        <div class="content">
                            <h2 class="text">HEURE MOYENNE DE DÉPART</h2>
                            <div class="number">{{ data.moy_gone }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey">
                        <div class="content">
                            <h2 class="text">Employé avec le plus d'exception</h2>
                            <div class="number"></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey">
                        <div class="content">
                            <h2 class="text">Département avec le plus d'exception</h2>
                            <div class="number"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->

            <div class="block-header">
                <h2>REGISTRE</h2>
            </div>
            <PresenceTableComponent />

            <!--  -->
            <div class="block-header">
                <h2>HEURE DE TRAVAIL PAR MOIS</h2>
            </div>
            <div class="row clearfix">
                <!-- GRAPHE -->
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="body">
                            <canvas id="stats"></canvas>
                        </div>
                    </div>
                </div>

                <!-- FILTRE -->
                <div class="col-lg-3 col-md-3 col-sm-9 col-xs-9">
                    <div class="card">
                        <div class="header">
                            <div class="btn-group" role="group">
                                <button type="button" :class="{ 'bg-grey': this.user_filter == 'e' }"
                                    v-on:click="user_filtering('e')" class="btn waves-effect waves-light">EMPLOYÉS</button>
                                <button type="button" :class="{ 'bg-grey': this.user_filter == 'p' }"
                                    v-on:click="user_filtering('p')" class="btn waves-effect waves-light">POSTES</button>
                            </div>
                        </div>
                        <div class="body">
                            <div v-if="this.user_filter == 'e'" class=""
                                style="position:relative; overflow-y: auto; height: 200px;">
                                <button type="button" class="list-group-item" v-on:click="barchatSearch(user.id)"
                                    :class="{ 'bg-blue-grey': this.idFilter == user.id }" v-for="user in users"
                                    :key="user.id">
                                    {{ user.first_name }} {{ user.last_name }}
                                </button>
                            </div>
                            <div v-else class="" style="position:relative; overflow-y: auto; height: 200px;">
                                <button type="button" class="list-group-item" v-on:click="barchatSearch(post.id)"
                                    :class="{ 'bg-blue-grey': this.idFilter == post.id }" v-for="post in posts"
                                    :key="post.id">
                                    {{ post.label }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="page-loader-wrapper" v-if="loader">
            <div class="loader">
                <div class="preloader">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                <p>Chargement...</p>
            </div>
        </div>
    </section>
</template>

<script>
import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'
import PresenceTableComponent from '@/components/tables/PresenceTable'

import axios from 'axios'
import Chart from 'chart.js/auto'


export default {
    name: 'PresenceView',
    components: {
        HeadComponent,
        MainsideComponent,
        PresenceTableComponent
    },
    data() {
        return {
            loader: false,
            user_filter: 'e',
            barchart: null,
            data: {},
            users: [{ 'id': 0, 'first_name': 'Tout', 'last_name': '' }],
            posts: [{ 'id': 0, 'label': 'Tout' }],
            workhours: [],
            idFilter: 0
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        this.loader = true
        await this.getUsersAndPosts()
        await this.getData()
        await this.getWorkHours()
        this.loader = false
    },
    unmounted() {

    },
    created() {

    },
    methods: {

        async getData() {
            await axios.get(this.$store.state.URL_API + 'moyennePointage')
                .then((res) => {
                    // console.log(res.data)
                    this.data = res.data
                })
                .catch((error) => {
                    this.loader = false
                    console.error(error)
                })
        },

        async getWorkHours() {
            await axios.post(this.$store.state.URL_API + 'getWorkHours', { filter: this.user_filter, id: this.idFilter })
                .then((res) => {
                    console.log(res.data)
                    this.workhours = res.data.data
                    this.initchart()
                })
                .catch((error) => {
                    this.loader = false
                    console.error(error)
                })
        },

        async getUsersAndPosts() {
            this.users = [{ 'id': 0, 'first_name': 'Tout', 'last_name': '' }]
            this.posts = [{ 'id': 0, 'label': 'Tout' }],
                await axios.get(this.$store.state.URL_API + 'getUsersAndPosts')
                    .then((res) => {
                        // console.log(res.data)
                        res.data.users.forEach(user => {
                            this.users.push(user)
                        });
                        res.data.posts.forEach(post => {
                            this.posts.push(post)
                        });
                    })
                    .catch((error) => {
                        this.loader = false
                        console.error(error)
                    })
        },

        initchart() {
            if (this.barchart != null)
                this.barchart.destroy()
            this.barchart
                = new Chart(
                    document.getElementById('stats'),
                    {
                        type: 'line',
                        data: {
                            labels: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
                            datasets: [
                                {
                                    label: '',
                                    data: this.workhours,
                                },
                            ]
                        },
                        options: {
                            responsive: true,
                            legend: false
                        }
                    }
                )
        },

        barchatSearch(id){
            this.idFilter = id
            this.getWorkHours()
        },

        user_filtering(fil) {
            if (fil != this.filter) {
                this.idFilter = 0
                this.user_filter = fil
                this.getWorkHours()
            }
        },

    }
}

</script>