<template>
    <HeadComponent />
    <MainsideComponent target="6"/>

    <section class="content">
        <div class="container-fluid">
            <!--  -->
            <div class="block-header">
                <h2>LISTE DES DEPARTEMENTS</h2>
            </div>

            <DepartementTableComponent />

            <div class="block-header">
                <h2>AJOUTER UN NOUVEAU DEPARTEMENT</h2>
            </div>

            <NewDepartementComponent />

            <!-- LOADER -->
            <div class="page-loader-wrapper" v-if="loader">
                <div class="loader">
                    <div class="preloader">
                        <div class="spinner-layer pl-indigo">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Chargement...</p>
                </div>
            </div>
            <!--  -->
        </div>
    </section>
</template>

<script>
import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'
import DepartementTableComponent from '@/components/tables/DepartementTable'
import NewDepartementComponent from '@/components/create/NewDepartement'

export default {
    name: 'DepartementView',
    components: {
        HeadComponent,
        MainsideComponent,
        NewDepartementComponent,
        DepartementTableComponent,
    },
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.loadpage()
    },
    created() {

    },
    methods: {
        loadpage() {
            this.loader = true
            var data = this
            setTimeout(() => {
                data.loader = false
            }, 500);
        }
    }
}

</script>