import { createStore } from 'vuex'

export default createStore({
  state: {
    'connected': localStorage.getItem('connected') ?? false,
    'URL_API': 'https://attendance-backend.lce-ci.com/api/',
    'URL': 'https://attendance-backend.lce-ci.com/',
    // 'URL_API': 'http://172.20.10.3:8000/api/',
    // 'URL': 'http://172.20.10.3:8000/',
    'token': localStorage.getItem('token') ?? '',
    'auth_attendance': JSON.parse(localStorage.getItem('auth_attendance')) ?? null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
