<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <!-- HEADER -->
                <div class="header">
                    <!--  -->
                    <div class="btn-group" role="group">
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'm' }"
                            v-on:click="period_filtering('m')" class="btn waves-effect waves-light">CE
                            MOIS</button>
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'p' }"
                            v-on:click="period_filtering('p')" class="btn waves-effect waves-light">PERSONNALISER</button>
                        <!--  -->
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == 'emp' }"
                            v-on:click="type_filtering('emp')" class="btn waves-effect waves-light">PAR EMPLOYÉ</button>
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == 'dep' }"
                            v-on:click="type_filtering('dep')" class="btn waves-effect waves-light">PAR DÉPARTEMENT</button>
                    </div>
                    <!--  -->
                    <ul v-show="period_filter == 'p'" class="header-dropdown m-r--5">
                        <input v-model="toSend.start" type="date" />
                        -
                        <input v-model="toSend.end" type="date" />
                        -
                        <button type="button" v-on:click="getData()"
                            class="btn btn-blue-grey btn-circle waves-effect waves-circle waves-float">
                            <i class="material-icons">autorenew</i>
                        </button>
                    </ul>
                </div>

                <!-- TABLE -->
                <div class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table
                            class="table table-bordered table-striped table-hover js-exportable tableExceptionClassement">
                            <thead>
                                <tr>
                                    <th>N° d'ordre</th>
                                    <th v-show="toSend.filter == 'emp'">Nom</th>
                                    <th v-show="toSend.filter == 'emp'">Prénoms</th>
                                    <th v-show="toSend.filter == 'dep'">Département</th>
                                    <th>Nombre d'absence</th>
                                    <th>Nombre de retard</th>
                                    <th>Nombre de départ anticipé</th>
                                    <th>Total exceptions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="classement in classements" :key="classement.id">
                                    <td>{{ classement.number }}</td>
                                    <td v-show="toSend.filter == 'emp'">{{ classement.first_name }}</td>
                                    <td v-show="toSend.filter == 'emp'">{{ classement.last_name }}</td>
                                    <td v-show="toSend.filter == 'dep'">{{ classement.label }}</td>
                                    <td>
                                        <center>{{ classement.nb_a <= 9 ? '0' + classement.nb_a : classement.nb_a
                                        }}</center>
                                    </td>
                                    <td>
                                        <center>{{ classement.nb_r <= 9 ? '0' + classement.nb_r : classement.nb_r
                                        }}</center>
                                    </td>
                                    <td>
                                        <center>{{ classement.nb_d <= 9 ? '0' + classement.nb_d : classement.nb_a
                                        }}</center>
                                    </td>
                                    <td>
                                        <center>{{ classement.nb_t <= 9 ? '0' + classement.nb_t : classement.nb_a
                                        }}</center>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { initdatable } from '@/js/functions'
import axios from 'axios'

export default {
    name: 'ExceptionClassementTableComponent',
    components: {

    },
    data() {
        return {
            table_load: false,
            period_filter: 'm',
            type_filter: 'emp',
            classements: [],
            toSend: { filter: 'emp', start: null, end: null }
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    methods: {

        getData() {
            this.table_load = true
            axios.post(this.$store.state.URL_API + 'classement', this.toSend)
                .then((res) => {
                    this.classements = res.data.data
                    initdatable('.tableExceptionClassement')
                    this.table_load = false

                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        period_filtering(fil) {
            if (fil != this.period_filter) {
                this.period_filter = fil
                if (fil == 'm') {
                    this.toSend.start = null;
                    this.toSend.end = null;
                    this.getData()
                }
            }
        },

        type_filtering(fil) {
            if (fil != this.toSend.filter) {
                this.toSend.filter = fil
                this.getData()
            }
        },
    }
}

</script>