<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <!-- HEADER -->
                <div class="header">
                    <!--  -->
                    <div class="btn-group" role="group">
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'm' }"
                            v-on:click="period_filtering('m')" class="btn waves-effect waves-light">CE
                            MOIS</button>
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'p' }"
                            v-on:click="period_filtering('p')" class="btn waves-effect waves-light">PERSONNALISER</button>
                        <!--  -->
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == 'a' }"
                            v-on:click="type_filtering('a')" class="btn waves-effect waves-light">ABSENCES</button>
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == 'r' }"
                            v-on:click="type_filtering('r')" class="btn waves-effect waves-light">RÉTARDS</button>
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == 'd' }"
                            v-on:click="type_filtering('d')" class="btn waves-effect waves-light">DÉPAERTS
                            ANTICIPÉS</button>
                    </div>
                    <!--  -->
                    <ul v-show="period_filter == 'p'" class="header-dropdown m-r--5">
                        <input v-model="toSend.start" type="date" />
                        -
                        <input v-model="toSend.end" type="date" />
                        -
                        <button type="button" v-on:click="getData()"
                            class="btn btn-blue-grey btn-circle waves-effect waves-circle waves-float">
                            <i class="material-icons">autorenew</i>
                        </button>
                    </ul>
                </div>

                <!-- TABLE -->
                <div v-if="this.exceptions.length == 0" class="alert bg-black">
                    <center>Pas de données trouvées</center>
                </div>
                <div v-else class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table class="table table-bordered table-striped table-hover tableException">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Employé</th>
                                    <th>Type</th>
                                    <th>Validations</th>
                                    <th>Statut</th>
                                    <th>Temps manqué</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="exception in exceptions" :key="exception.id">
                                    <td><span class="label label-default bg-pink"
                                            :class="{ 'bg-orange': exception.is_perm != 1 }">{{ exception.is_perm ?
                                                'PERMISSION' : 'EXCEPTION SIMPLE' }}</span></td>
                                    <td>{{ exception.date_start }}</td>
                                    <td>{{ exception.user.first_name }} {{ exception.user.last_name }}</td>
                                    <td><span class="label label-default bg-purple">{{ exception.type.label.toUpperCase()
                                    }}</span></td>
                                    <td>
                                        <span class="label label-default bg-cyan"
                                            v-if="exception.requests.length > 0 && exception.requests[0].validation_sup == 1">SUPERIEUR</span>
                                        <span
                                            v-show="exception.requests.length > 0 && (exception.requests[0].validation_sup == 1 && exception.requests[0].validation_rh == 1)">
                                            - </span>
                                        <span class="label label-default bg-teal"
                                            v-if="exception.requests.length > 0 && exception.requests[0].validation_rh == 1">RH</span>
                                    </td>
                                    <td>
                                        <span v-if="exception.state == '2'" class="label label-default bg-green">VALIDÉE</span>
                                        <span v-else-if="exception.state == '1'" class="label label-default bg-orange">EN COURS DE VALIDATION</span>
                                        <span v-else class="label label-default bg-red">NON JUSTIIÉE</span>
                                    </td>
                                    <td>
                                        {{ exception.hour_issue.substring(0, 2) }}h {{ exception.hour_issue.substring(3, 5)
                                        }}min
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { initdatable } from '@/js/functions'
import axios from 'axios'

export default {
    name: 'ExceptionTableComponent',
    components: {

    },
    data() {
        return {
            table_load: false,
            period_filter: 'm',
            toSend: { filter: 'a', start: null, end: null },
            exceptions: []
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    methods: {

        getData() {
            this.table_load = true
            axios.post(this.$store.state.URL_API + 'getAllExceptions', this.toSend)
                .then((res) => {
                    console.log(res.data)
                    this.exceptions = res.data.data
                    initdatable('.tableException')
                    this.table_load = false

                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        period_filtering(fil) {
            if (fil != this.period_filter) {
                this.period_filter = fil
                if (fil == 'm') {
                    this.toSend.start = null
                    this.toSend.end = null
                    this.getData()
                }
            }
        },

        type_filtering(fil) {
            if (fil != this.toSend.filter) {
                this.toSend.filter = fil
                this.getData()
            }
        },
    }
}

</script>