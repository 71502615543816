<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <!-- TABLE -->
                <div v-if="this.profiles.length == 0" class="alert bg-black">
                    <center>Pas de données trouvées</center>
                </div>
                <div class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table id="mainTable" class="table table-bordered table-striped table-hover tableDepartement">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Libéllé</th>
                                    <th>Nombre d'employé</th>
                                    <th>Responsable</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="profile in profiles" :key="profile.id">
                                    <td>{{ profile.id }}</td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="profile.label" type="text" id="lastname" class="form-control"
                                                placeholder="Entrer le libéllé du département" required>
                                        </div>
                                    </td>
                                    <td>
                                        <center>{{ profile.users.length }}</center>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <select v-model="profile.responsable.id" id="poste" class="form-control"
                                                required>
                                                <option value="">-- Pas de responsable --</option>
                                                <option v-for="user in users" :key="user.id" :value="user.id">
                                                    {{ user.first_name }} {{ user.last_name }}</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            v-on:click="updateProfile(profile.id, profile.label, profile.responsable.id)"
                                            type="button" class="btn bg-blue waves-effect">
                                            <i class="material-icons">send</i>
                                        </button>
                                        -
                                        <button v-on:click="removeProfile(profile.id)" type="button"
                                            class="btn bg-red waves-effect">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'DepartementTableComponent',
    components: {

    },
    data() {
        return {
            table_load: false,
            profiles: [],
            users: []
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        await this.getUsers()
        await this.getData()
    },
    created() {

    },
    methods: {

        async getData() {
            this.table_load = true
            axios.get(this.$store.state.URL_API + 'getAllProfiles')
                .then((res) => {
                    this.profiles = res.data.data
                    this.table_load = false
                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        async updateProfile(id, label, sup_id) {
            Swal.fire({
                title: "Confirmer la modification",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.$store.state.URL_API + 'updateProfile/' + id, { label: label, sup_id: sup_id })
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Département modifié',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'error'
                            )
                            console.error(error)
                        })
                }
            })

        },

        async removeProfile(id) {
            Swal.fire({
                title: "Confirmer la suppression",
                text: "Cette action est ireversible",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.$store.state.URL_API + 'removeProfile/' + id)
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Département supprimé',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },

        async getUsers() {
            axios.get(this.$store.state.URL_API + 'getAllEmployees')
                .then((res) => {
                    this.users = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
    }
}

</script>