<template>
  <HeadComponent />
  <MainsideComponent />

  <section class="content">
    <div class="container-fluid">
      <!--  -->
      <!-- <div class="block-header">
        <h2>TABLEAU DE BORD</h2>
      </div> -->

      <!-- CASRD -->
      <div class="row clearfix">
        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="info-box bg-purple hover-expand-effect">
            <div class="icon">
              <i class="material-icons">compare_arrows</i>
            </div>
            <div class="content">
              <h2 class="text">PRESENCES</h2>
              <div class="number ">{{ data.presences }}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="info-box bg-red hover-expand-effect">
            <div class="icon">
              <i class="material-icons">info</i>
            </div>
            <div class="content">
              <h2 class="text">ABSENCESS</h2>
              <div class="number">{{ data.absences }}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="info-box bg-orange hover-expand-effect">
            <div class="icon">
              <i class="material-icons">person_add</i>
            </div>
            <div class="content">
              <h2 class="text">EMPLOYES</h2>
              <div class="number">{{ data.employees }}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="info-box bg-light-green hover-expand-effect">
            <div class="icon">
              <i class="material-icons">view_module</i>
            </div>
            <div class="content">
              <h2 class="text">DEPARTEMENTS</h2>
              <div class="number">{{ data.departements }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix">

        <!-- PRESENCES -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
            <div class="header">
              <h2>
                PRESENCES ENREGISTREES AUJOURD'HUI
              </h2>
            </div>
            <div v-if="this.data.today_presences == null || this.data.today_presences.length == 0" class="alert bg-black">
              <center>Pas de présences enregistrées aujuourd'hui</center>
            </div>
            <div v-else class="body table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>Prénoms</th>
                    <th>Arrivé</th>
                    <th>Départ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="presence in data.today_presences" :key="presence.id">
                    <th scope="row">--</th>
                    <td>{{ presence.user.first_name }}</td>
                    <td>{{ presence.user.last_name }}</td>
                    <td>{{ presence.hour_start }}</td>
                    <td>{{ presence.hour_end }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <!-- GRAPHE -->
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
            <div class="header">
              <h2>Les exeptions par type</h2>
              <ul class="header-dropdown m-r--5">
                <div class="btn-group" role="group">
                  <button type="button" :class="{ 'bg-grey': this.filter == 'w' }" v-on:click="filtering('w')"
                    class="btn waves-effect waves-light">SEMAINE</button>
                  <button type="button" :class="{ 'bg-grey': this.filter == 'm' }" v-on:click="filtering('m')"
                    class="btn waves-effect waves-light">MOIS</button>
                  <button type="button" :class="{ 'bg-grey': this.filter == 'y' }" v-on:click="filtering('y')"
                    class="btn waves-effect waves-light">ANNÉE</button>
                </div>
              </ul>
            </div>
            <div class="body">
              <canvas id="stats"></canvas>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- LOADER -->
    <div class="page-loader-wrapper" v-if="loader">
      <div class="loader">
        <div class="preloader">
          <div class="spinner-layer pl-indigo">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <p>Chargement...</p>
      </div>
    </div>

  </section>
</template>

<script>

import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'

import axios from 'axios'
import Chart from 'chart.js/auto'


export default {
  name: 'DashboardView',
  components: {
    HeadComponent,
    MainsideComponent
  },
  data() {
    return {
      loader: false,
      barchart: null,
      filter: 'w',
      data: {}
    }
  },
  mounted() {
    this.getData()
  },
  created() {

  },
  methods: {

    getData() {
      this.loader = true
      axios.post(this.$store.state.URL_API + "getRHDashboard", { filter: this.filter })
        .then((res) => {
          console.log(res.data)
          this.data = res.data
          this.initchart();
          this.loader = false
        })
        .catch((error) => {
          console.error(error)
          this.loader = false

        })
    },

    initchart() {
      if (this.barchart != null) {
        console.log('destroy old')
        this.barchart.destroy()
      }
      console.log('create new')
      this.barchart = new Chart(
        document.getElementById('stats'),
        {
          type: 'bar',
          data: {
            labels: this.data.labels,
            datasets: [
              {
                label: 'Rétards',
                data: this.data.exceptions['r'],
              },
              {
                label: 'Absences',
                data: this.data.exceptions['a'],
              },
              {
                label: 'Départ anticipés',
                data: this.data.exceptions['d'],
              },
            ]
          },
          options: {
            responsive: true,
            legend: false
          }
        }
      )

    },

    filtering(fil) {
      if (fil != this.filter) {
        this.filter = fil
        console.log(this.filter)
        this.getData()
      }
    },
  }
}

</script>