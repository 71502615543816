import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import SigninView from '@/views/auth/SigninView'
import ErrorView from '@/views/404View'

import DashboardView from '@/views/navigation/DashboardView'
import PresenceView from '@/views/navigation/PresenceView'
import ExceptionView from '@/views/navigation/ExceptionView'
import RequestView from '@/views/navigation/RequestView'
import EmployeeView from '@/views/navigation/EmployeeView'
import ZonesView from '@/views/navigation/ZonesView'
import DaysView from '@/views/navigation/DaysView'
import DepartementView from '@/views/navigation/DepartementView'
import ShiftsView from '@/views/navigation/ShiftsView'

const routes = [
  {
    path: '/',
    redirect: function () {
      if (store.state.connected == true) {
        return '/dashboard'
      }
      else {
        return '/signin'
      }
    }
  },

  // auth
  {
    path: '/signin',
    name: 'signin',
    component: SigninView
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
  },
  {
    path: '/:catchAll(.*)',
    name: 'ErrorPage',
    component: ErrorView
  },

  // navidagtion
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/gestion/presences',
    name: 'presences',
    component: PresenceView
  },
  {
    path: '/gestion/exceptions',
    name: 'exceptions',
    component: ExceptionView
  },
  {
    path: '/gestion/requetes',
    name: 'requetes',
    component: RequestView
  },
  {
    path: '/personnel/employes',
    name: 'employes',
    component: EmployeeView
  },
  {
    path: '/personnel/departements',
    name: 'departements',
    component: DepartementView
  },
  {
    path: '/personnel/shifts',
    name: 'shifts',
    component: ShiftsView
  },
  {
    path: '/configuration/zonesautorisees',
    name: 'zones',
    component: ZonesView
  },
  {
    path: '/configuration/joursautorises',
    name: 'days',
    component: DaysView
  },
  //
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
