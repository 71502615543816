<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="body">
                    <form @submit.prevent="addProfile()">
                        <div class="row clearfix">
                            <div class="col-md-3">
                                <label for="lastname">Symbole</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="profildata.symbol" type="text" id="lastname" class="form-control"
                                            placeholder="Entrer le symbole du service" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label for="lastname">Libéllé</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="profildata.label" type="text" id="lastname" class="form-control"
                                            placeholder="Entrer le libéllé du service" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label for="lastname">Heure de debut</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input  v-model="profildata.hour_start" type="time" id="lastname" class="form-control"
                                            placeholder="Entrer le libéllé du service" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label for="lastname">Heure de fin</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input  v-model="profildata.hour_end" type="time" id="lastname" class="form-control"
                                            placeholder="Entrer l'heure de fin du service" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button type="submit" class="btn btn-primary m-t-15 waves-effect">AJOUTER +</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'NewShiftComponent',
    components: {

    },
    data() {
        return {
            profildata: { symbol: null, label: null, hour_start: null, hour_end: null },
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {

    },
    created() {

    },
    methods: {

        async addProfile() {
            Swal.fire({
                title: "Confirmer l'ajout du service",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(this.$store.state.URL_API + 'shifts', this.profildata)
                        .then(async (res) => {
                            if (res.data.status) {
                                await Swal.fire(
                                    {
                                        title: 'Succès !',
                                        text: 'Horaire ajouté',
                                        icon: 'success',
                                    }
                                ).then(() => {
                                    window.location.reload()
                                })
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'error'
                            )
                        })
                }
            })
        }
    }
}

</script>