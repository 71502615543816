<template>
    <HeadComponent />
    <MainsideComponent target="6"/>

    <section class="content">
        <div class="container-fluid">
            <!--  -->
            <div class="block-header">
                <h2>LISTE DES SERVICES</h2>
            </div>

            <ShiftsTableComponent />

            <div class="block-header">
                <h2>AJOUTER UN NOUVEAU SERVICE</h2>
            </div>

            <NewShiftComponent />

            <!-- LOADER -->
            <div class="page-loader-wrapper" v-if="loader">
                <div class="loader">
                    <div class="preloader">
                        <div class="spinner-layer pl-indigo">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Chargement...</p>
                </div>
            </div>
            <!--  -->
        </div>
    </section>
</template>

<script>
import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'
import ShiftsTableComponent from '@/components/tables/ShiftsTable'
import NewShiftComponent from '@/components/create/NewShift'

export default {
    name: 'DepartementView',
    components: {
        HeadComponent,
        MainsideComponent,
        NewShiftComponent,
        ShiftsTableComponent,
    },
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.loadpage()
    },
    created() {

    },
    methods: {
        loadpage() {
            this.loader = true
            var data = this
            setTimeout(() => {
                data.loader = false
            }, 500);
        }
    }
}

</script>