<template>
    <HeadComponent />
    <MainsideComponent target="5"/>

    <section class="content">
        <div class="container-fluid">
            <!--  -->
            <div class="block-header">
                <h2>LISTE DES EMPLOYÉS</h2>
            </div>

            <EmployeeTableComponent />

            <div class="block-header">
                <h2>AJOUTER UNE NOUVELLE PERSONNE</h2>
            </div>

            <NewEmployeeComponent />


            <!-- LOADER -->
            <div class="page-loader-wrapper" v-if="loader">
                <div class="loader">
                    <div class="preloader">
                        <div class="spinner-layer pl-indigo">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Chargement...</p>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'
import EmployeeTableComponent from '@/components/tables/EmployeeTable'
import NewEmployeeComponent from '@/components/create/NewEmployee'

export default {
    name: 'EmployeeView',
    components: {
        HeadComponent,
        MainsideComponent,
        NewEmployeeComponent,
        EmployeeTableComponent,
    },
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.loadpage()
    },
    created() {

    },
    methods: {
        loadpage() {
            this.loader = true
            var data = this
            setTimeout(() => {
                data.loader = false
            }, 500);
        }
    }
}

</script>