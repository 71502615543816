<template>
    <div class="four-zero-four">
        <div class="four-zero-four-container">
            <div class="error-code">404</div>
            <div class="error-message">Cette page n'existe pas</div>
            <div class="button-place">
                <a href="/" class="btn btn-default btn-lg waves-effect">RETOUR</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ErrorView',
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
    },
    mounted() {

    },
    created() {

    },
    methods: {

    }
}

</script>